import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	trackEvent({ params }) {
		if (window.plausible == undefined) { return }

		let event = params.event
		let props = params
		delete props.event

		window.plausible(event, {
			props: props
		})
	}
}
